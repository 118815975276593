<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t('Add') : $t('Edit') }}

    Form.modal-editor(
      :model="formData"
      ref="serialNumberEditorForm"
      :rules="serialNumberEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="type" v-if="editType === 'new'")
            Row
              Col.title(span="9")
                h3 {{$t('SessionSerial.Type')}}
              Col(span="10" offset="5")
                Select(
                  v-model="formData.type"
                  :placeholder="$t('SessionSerial.Type')"
                  filterable
                )
                  Option(v-for="item in typeList" :value="item.value" :key="`sn-${item.value}`") {{ item.label }}

          FormItem(prop="name")
            Row
              Col.title(span="9")
                h3 {{$t('SessionSerial.Name')}}
              Col(span="10" offset="5")
                Input(
                  v-model="formData.name"
                )

          FormItem(prop="description")
            Row
              Col.title(span="9")
                h3 {{$t('Description')}}
              Col(span="10" offset="5")
                Input(
                  v-model="formData.description"
                  type="textarea"
                )

          FormItem(prop="operators")
            Row
              Col.title(span="9")
                h3 {{$t('Task operators')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.operators"
                  :placeholder="$t('Please select task operators')"
                  filterable
                )
                  Option(
                    v-for="item in operators"
                    :value="item._id"
                    :key="item._id"
                  ) {{ item.fullname }}
          FormItem(prop="games")
            Row
              Col.title(span="9")
                h3 {{$t('Games')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.games"
                  :placeholder="$t('Please select task games')"
                  filterable
                )
                  Option(
                    v-for="item in games"
                    :value="item._id"
                    :key="item._id"
                  ) {{ item.name }}

          FormItem
            Row
              Col.title(span="9")
                h3 {{$t('Campaign Time range')}}
              Col.val(span="14" offset="1")
                DatePicker.app-tool.date(
                  v-model="formData.startTime"
                  :options="dateOptions"
                  :placeholder="$t('Select date')"
                )
                Icon(type="md-arrow-forward" size="20" style="float: left; margin: 6px 8px 6px 4px;")
                DatePicker.app-tool.date(
                  v-model="formData.endTime"
                  :options="dateOptions"
                  :placeholder="$t('Select date')"
                )

          FormItem(prop="targetValue")
            Row
              Col.title(span="9")
                h3 {{$t('SessionSerial.Amount')}}
              Col(span="10" offset="5")
                InputNumber(
                  v-model="formData.targetValue"
                  :step="10"
                  controls-outside
                )

          FormItem(prop="prefix" v-if="editType === 'new'")
            Row
              Col.title(span="9")
                h3 {{$t('SessionSerial.Prefix')}}
              Col(span="10" offset="5")
                Input(
                  v-model="formData.prefix"
                )
          FormItem(prop="prefix" v-if="editType === 'edit'")
            Row
              Col.title(span="9")
                h3 Key
              Col(span="10" offset="5")
                Input(
                  v-model="formData.key"
                  :disabled="true"
                )

          FormItem(prop="rewards")
            Row
              Col.title(span="9")
                h3 {{$t('SessionSerial.Rewards')}}
              Col(span="10" offset="5")
                Input(
                  v-model="formData.rewards"
                  type="textarea"
                )

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Time from '~m/time'
export default {
  name: 'serial-number-editor',
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  mixins: [Time],
  data () {
    return {
      typeList: [
        {
          label: this.$t('SessionSerial.One to many'),
          value: 'multiple'
        },
        {
          label: this.$t('SessionSerial.One to one'),
          value: 'single'
        }
      ],
      isUpdating: false
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('game', {
      games: 'getAllForSelector'
    }),
    ...mapGetters('operator', { operators: 'getAllForSelector' }),
    serialNumberEditorRule () {
      return {
        'formData.point': [{ required: true, message: this.$t('Input line point'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions('game', { getGames: 'findAll' }),
    ...mapActions('operator', { getOperators: 'findAll' }),
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
    },

    addUrl () {
      this.formData.urls.push('')
    },

    handleSubmit () {
      this.$refs.serialNumberEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {
    if (!_.size(this.operators)) await this.getOperators()
    if (!_.size(this.games)) await this.getGames()
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.serialNumber
  padding: 0 10px
.uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

.upload-btn
  width: 130px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
</style>
