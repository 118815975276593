<template lang="pug">
  .table
    AppTable.app-table(:columns="columns" :data="listData" border)
        template(slot-scope="{ row, index }" slot="createdAt")
          span {{ timeFormat(row.createdAt, 'YY-MM-DD HH:mm') }}
          Icon(type="md-arrow-forward" :style="{margin: '0 5px'}")
          span {{ timeFormat(row.updatedAt, 'YY-MM-DD HH:mm') }}
        template(slot-scope="{ row, index }" slot="startTime")
          span {{ timeFormat(row.startTime, 'YY-MM-DD HH:mm') }}
          Icon(type="md-arrow-forward" :style="{margin: '0 5px'}")
          span {{ timeFormat(row.endTime, 'YY-MM-DD HH:mm') }}
        template(slot-scope="{ row, index }" slot="targetValue")
          span {{ row.currentValue }}/{{ row.targetValue }}
        template(slot-scope="{ row, index }" slot="status")
          span(:class="getColor(row.status)") {{ $t(row.status) }}

        template(slot-scope="{ row, index }" slot="actions")
          Button.actions(
            type="success"
            size="small"
            icon="md-create"
            @click="showSerialNumberEditor(row)"
          )

    SerialNumberEditor(
      :form-data="serialNumberData"
      edit-type="edit"
      :visible="serialNumberEditorVisible"
      :on-update="onSerialNumberUpdate"
      :on-close="closeSerialNumberEditor"
    )
</template>
<script>
import AppTable from '~c/app-table'
import Time from '~m/time'
import { mapActions, mapGetters } from 'vuex'
import SerialNumberEditor from './serial-number-editor'
import to from 'await-to-js'
import moment from 'moment'

export default {
  name: 'serial-number-table',
  mixins: [Time],
  components: {
    SerialNumberEditor,
    AppTable
  },
  props: {
    listData: Array
  },
  data () {
    return {
      serialNumberData: null,
      serialNumberEditorVisible: false
    }
  },
  computed: {
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    columns () {
      let columns = [
        {
          title: 'Key',
          key: 'key',
          align: 'center',
          width: 210
        },
        {
          title: this.$t('Created at'),
          slot: 'createdAt',
          align: 'center'
        },
        {
          title: this.$t('Campaign startTime'),
          slot: 'startTime',
          align: 'center'
        },
        {
          title: this.$t('SessionSerial.Name'),
          key: 'name'
        },
        {
          title: this.$t('description'),
          key: 'description',
          ellipsis: true
        },
        {
          title: this.$t('Rewards'),
          key: 'rewards',
          ellipsis: true
        },
        {
          title: this.$t('SessionSerial.Amount'),
          slot: 'targetValue',
          align: 'center',
          width: 160
        },
        {
          title: this.$t('Status'),
          slot: 'status',
          align: 'center',
          width: 160
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 90,
          align: 'center'
        }
      ]

      columns = columns.filter(c => c.remove !== true)
      return columns
    },

    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions('serialNumber', {
      findLinePoint: 'find',
      serialNumberUpdate: 'update',
      resetLinePoint: 'reset'
    }),
    ...mapActions('animation', {
      findAllAnimation: 'findAll'
    }),
    getColor (status) {
      return status.toLowerCase()
    },
    showSerialNumberEditor (row) {
      let mydata = _.cloneDeep(row)
      mydata.startTime = moment(mydata.startTime).format('YYYY-MM-DD')
      mydata.endTime = moment(mydata.endTime).format('YYYY-MM-DD')
      if (mydata.rewards) mydata.rewards = JSON.stringify(mydata.rewards)
      this.serialNumberData = mydata
      console.log(this.serialNumberData)
      this.serialNumberEditorVisible = true
    },

    async onSerialNumberUpdate () {
      if (this.serialNumberData.rewards) this.serialNumberData.rewards = JSON.parse(this.serialNumberData.rewards)

      const data = _.cloneDeep(this.serialNumberData)
      this.serialNumberData = null
      data.startTime = `${moment(data.startTime).format('YYYY-MM-DD')} 00:00:00`
      data.endTime = `${moment(data.endTime).format('YYYY-MM-DD')} 23:59:59`
      let [err] = await to(this.serialNumberUpdate({ id: data._id, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Update serialNumber error'))
      }
      this.$Message.success(this.$t('Update serialNumber successful'))
      this.closeSerialNumberEditor()
    },
    closeSerialNumberEditor () {
      this.serialNumberEditorVisible = false
      setTimeout(() => {
        this.serialNumberData = null
      }, 500)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('serialNumber/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' }, trueValue: 'Enabled' }),
        h('Icon', { slot: 'close', props: { type: 'md-close' }, falseValue: 'Disabled' })
      ])
    }
  }
}
</script>
<style lang="sass">
.enabled
  color: #19be6b
.pending
  color: #808695
.used
  color: #ed4014

</style>
